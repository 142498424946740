<template>
  <div class="bg-white" v-if="openActivitiesList">
    <v-toolbar color="teal">
        <v-toolbar-title>
          <v-icon>{{ selectedCategory.icon }}</v-icon>
          {{ selectedCategory.name }}
        </v-toolbar-title>
        <v-btn-group color="white">
          <v-btn
            v-if="currentUser.admin"
            variant="text"
            icon="mdi-pencil-outline"
            @click="openUpdateCategoryModel()"
          ></v-btn>
          <v-btn
            v-if="activitiesList.length === 0 && currentUser.admin"
            variant="text"
            icon="mdi-trash-can-outline"
              @click="openRemoveCategoryModel()"
          ></v-btn>
        </v-btn-group>
        <v-spacer></v-spacer>

        <SearchForm @search="handleSearch"/>

        <v-btn
          v-if="currentUser.admin"
          @click="newActivityModel = true"
          prepend-icon="mdi-plus"
        >
          Nova Atividade
        </v-btn>
      </v-toolbar>
    <v-table class="table-container">
      <template v-slot:default>
        <thead class="table-header">
        <tr>
          <th class="px-4 py-3">
            Nome
            <v-icon size="small" v-if="sortNameOrder" @click="sortBy('name', 'asc'); sortNameOrder = !sortNameOrder">
              mdi-arrow-up
            </v-icon>
            <v-icon size="small" v-if="!sortNameOrder" @click="sortBy('name', 'dsc'); sortNameOrder = !sortNameOrder">
              mdi-arrow-down
            </v-icon>
          </th>
          <th class="px-4 py-3 text-center">Recomendação</th>
          <th v-if="currentUser.admin" class="px-4 py-3 text-center">Opções</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(activity, i) in filteredActivities" :key="i" class="border-b">
          <td class="px-4 py-3 font-medium text-grey-900">
            {{ activity.name }}
            <v-btn
              variant="flat"
              class="ma-0"
              icon="mdi-help-circle-outline"
              @click.stop="showActivityDialog(activity)"
            ></v-btn>
          </td>
          <td class="px-4 py-3 text-center">
            <v-row no-gutters>
              <v-col
                v-for="(month, index) in monthsNum"
                :key="index"
              >
                <v-checkbox
                  v-model="recommendedMonths[i]"
                  :label="month.toString()"
                  :value="month"
                  color="success"
                  @update:modelValue="storeChanges(activity._id, i)"
                ></v-checkbox>
              </v-col>
            </v-row>
          </td>
          <td class="px-4 py-3 text-center">
            <v-btn-group variant="outlined" divided>
              <v-btn
                v-if="currentUser.admin"
                icon="mdi-pencil"
                @click="openUpdateActivityModel(activity._id)"
              ></v-btn>
              <v-btn
                v-if="stage === 'development'"
                icon="mdi-trash-can-outline"
                @click="openRemoveActivityModel(activity._id)"
              ></v-btn>
              <v-btn
                :icon="activity.active ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                @click="toogleActivityState(activity._id)"
              ></v-btn>
              <v-btn
                :disabled="!changes.find(act => act._id === activity._id)"
                icon="mdi-content-save-outline"
                @click="saveChanges(activity._id)"
              ></v-btn>
            </v-btn-group>
          </td>
        </tr>
      </tbody>
      </template>
    </v-table>

    <!-- ACTIVITY DESCRIPTION -->
    <ActivityDescriptionDialog v-model="activityDescriptionModel" :currentUser="currentUser" :activity="activity" />

    <!-- CREATE NEW ACTIVITY -->
    <CreateActivityDialog v-model="newActivityModel" :currentUser="currentUser" :catId="selectedCategory._id" @refresh="categoriesAndActivitiesStore.fetchData()" />

    <!-- REMOVE ACTIVITY -->
    <RemoveActivityDialog v-model="removeActivityModel" :currentUser="currentUser" :activityId="removeActivityId" @refresh="categoriesAndActivitiesStore.fetchData()" />

    <!-- UPDATE ACTIVITY -->
    <UpdateActivityDialog v-model="updateActivityModel" :currentUser="currentUser" :activityId="updateActivityId" @refresh="categoriesAndActivitiesStore.fetchData()" />

    <!-- REMOVE CATEGORY -->
    <RemoveCategoryDialog v-model="removeCategoryModel" :currentUser="currentUser" :categoryId="selectedCategory._id" :openEmptyAlert="emit('openEmptyAlert')" />

    <!-- UPDATE CATEGORY -->
    <UpdateCategoryDialog v-model="updateCategoryModel" :currentUser="currentUser" :category="selectedCategory" @refresh="refresh" />
  </div>
</template>

<script setup>
import { ref, watch, computed } from 'vue'
import axios from 'axios'
import ActivityDescriptionDialog from '@/components/ActivityDescriptionDialog.vue'
import CreateActivityDialog from '@/components/CreateActivityDialog.vue'
import RemoveActivityDialog from '@/components/RemoveActivityDialog.vue'
import UpdateActivityDialog from '@/components/UpdateActivityDialog.vue'
import RemoveCategoryDialog from '@/components/RemoveCategoryDialog.vue'
import UpdateCategoryDialog from '@/components/UpdateCategoryDialog.vue'
import SearchForm from '@/components/SearchForm.vue'
import { useCategoriesAndActivitiesStore } from '@/stores/categoriesAndActivitiesStore'
import { useUserStore } from '@/stores/userStore'
import { useSnackbarStore } from '@/stores/snackbarStore'
import { storeToRefs } from 'pinia'

const snackbarStore = useSnackbarStore()
const categoriesAndActivitiesStore = useCategoriesAndActivitiesStore()
const { activitiesList } = storeToRefs(categoriesAndActivitiesStore)
const userStore = useUserStore()
const { currentUser } = storeToRefs(userStore)

const api = import.meta.env.VITE_API
const stage = import.meta.env.VITE_STAGE

const openActivitiesList = defineModel()
const props = defineProps(['currentUser', 'category', 'year'])
const emit = defineEmits(['openEmptyAlert'])

const newActivityModel = ref(false)

const selectedCategory = ref(props.category)
const monthsNum = ref([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12])

const currentYear = new Date().getFullYear().toString()
const selectedYear = ref(props.year)

const search = ref('')
const removeCategoryModel = ref(false)
const updateCategoryModel = ref(false)
const updateActivityModel = ref(false)
const updateActivityId = ref(null)
const removeActivityModel = ref(false)
const removeActivityId = ref(null)

const sortNameOrder = ref(true)

const activityDescriptionModel = ref(false)
const activity = ref(null)
const activityActive = ref(null)
const activityAuthor = ref('')
const activityName = ref('')
const activityDescription = ref('')
const activityRecommended = ref([])
const activityCategory = ref('')

const recommendedMonths = ref([])
const changes = ref([])

watch(props, async () => {
  selectedCategory.value = props.category
  selectedYear.value = props.year

  if (selectedCategory.value !== null) {
    getRecommendedMonths()
  }
})

const showActivityDialog = (act) => {
  activity.value = act
  activityDescriptionModel.value = true
}

const getRecommendedMonths = () => {
  const combinedMonths = new Set()
  recommendedMonths.value = []

  filteredActivities.value.forEach(activity => {
    const recommendedYear = activity.recommended.find(rec => rec.year === selectedYear.value)
    if (recommendedYear) {
      recommendedYear.months.forEach(month => {
        combinedMonths.add(parseInt(month))
      })
    }
    recommendedMonths.value.push(Array.from(combinedMonths))
    combinedMonths.clear()
  })
}


const storeChanges = async (activityId, index) => {
  await getActivityById(activityId)

  // Get changed activity by activityId if exists
  const existingActivity = changes.value.find(activity => activity._id === activityId)

  if (existingActivity) {
    // If there is a change for given activityId then update it

    // Get current year recommended entry of activity before changes
    const recommendedDefaultEntry = activityRecommended.value.find(rec => rec.year === currentYear)

    // Get current year recommended entry of activity if exists
    const recommendedEntry = existingActivity.recommended.find(rec => rec.year === currentYear)

    if (recommendedEntry) {
      // Check if changes made are equal to activity before changes
      if (recommendedDefaultEntry.months.length === recommendedMonths.value[index].length) {
        recommendedDefaultEntry.months.forEach(month => {
          if (!recommendedMonths.value[index].includes(month)) {
            // If changes made are not equal then update change
            recommendedEntry.months = recommendedMonths.value[index]
            return true
          }
        })
      } else {
        // If changes made are not equal then update change
        recommendedEntry.months = recommendedMonths.value[index]
        return true
      }

      // If changes made are equal then remove it from changes
      const indexToRemove = changes.value.indexOf(existingActivity)
      changes.value.splice(indexToRemove, 1)
      return false
    } else {
      // If current year recommended entry of activity doesn't exist then push it
      existingActivity.recommended.push({
        year: currentYear,
        months: recommendedMonths.value[index]
      })
      return true
    }
  } else {
    // If there is no change for given activityId then push it
    const updatedActivity = {
      _id: activityId,
      active: activityActive.value,
      name: activityName.value,
      description: activityDescription.value,
      recommended: [{
        year: currentYear,
        months: recommendedMonths.value[index]
      }],
      category: activityCategory.value
    }

    changes.value.push(updatedActivity)
    return true
  }
}

const saveChanges = async (activityId) => {
  for (const activity of changes.value) {
    if (activity._id === activityId) {
      try {
        await saveActivity(activity)
        // Filter out the saved activity from changes array
        changes.value = changes.value.filter(act => act._id !== activityId)
      } catch (error) {
        snackbarStore.openSnackbar('Não foi possível salvar as alterações')
        console.error(`Error saving activity ${activity._id}:`, error)
      }
    }
  }
}

const getActivityById = async (id) => {
  await axios.get(api + '/status', { timeout: 2000 }).then(async () => {
    await axios.get(api + '/activity', {
      params: { activityId: id },
      headers: { Authorization: 'Bearer ' + currentUser.value.token }
    })
      .then(response => {
        activityActive.value = response.data.active
        activityAuthor.value = response.data.author
        activityName.value = response.data.name
        activityDescription.value = response.data.description
        activityRecommended.value = response.data.recommended
        activityCategory.value = response.data.category
      })
      .catch(error => {
        console.log(error)
        snackbarStore.openSnackbar('Não foi possível achar atividade')
      })
  }).catch(error => {
    console.log('Error fetching status:', error)
    snackbarStore.openSnackbar()
  })
}

const saveActivity = async (updatedActivity) => {
  await axios.get(api + '/status', { timeout: 2000 }).then(async () => {
    await axios.post(api + '/manager/activity', { author: currentUser.value.email, activity: updatedActivity }, { headers: { Authorization: 'Bearer ' + currentUser.value.token } })
      .then(async () => {
        categoriesAndActivitiesStore.fetchData()
        await axios.post(api + '/manager/revision', { userEmail: currentUser.value.email }, { headers: { Authorization: 'Bearer ' + currentUser.value.token } })
          .catch(error => {
            snackbarStore.openSnackbar('Não foi possível salvar as alterações no histórico')
            console.log(error)
          })
      })
      .catch(error => {
        snackbarStore.openSnackbar('Não foi possível salvar as alterações')
        console.log(error)
      })
  }).catch(error => {
    console.log('Error fetching status:', error)
    snackbarStore.openSnackbar()
  })
}

const toogleActivityState = async (activityId) => {
  await getActivityById(activityId)

  const updatedActivity = {
    _id: activityId,
    active: !activityActive.value,
    name: activityName.value,
    description: activityDescription.value,
    recommended: activityRecommended.value,
    category: activityCategory.value
  }

  await saveActivity(updatedActivity)

  // Updating activities store
  const index = filteredActivities.value.findIndex(a => a._id === activityId)
  if (index !== -1) {
    filteredActivities.value[index] = updatedActivity
  }
  
  const message = `Atividade ${updatedActivity.active ? 'ativada' : 'desativada'}`
  snackbarStore.openSnackbar(message, 'blue')
}

const handleSearch = (searchValue) => {
  search.value = searchValue
}

const sortBy = (key, sortOrder) => {
  if (!Array.isArray(filteredActivities.value)) {
    console.error('filteredActivities.value is not an array.')
    return
  }

  filteredActivities.value.sort((a, b) => {
    let aValue = ''
    let bValue = ''

    switch (key) {
      case 'name':
        aValue = a && a.name !== undefined && a.name !== null ? a.name.toString() : ''
        bValue = b && b.name !== undefined && b.name !== null ? b.name.toString() : ''
        break
      default:
        aValue = a && a.name !== undefined && a.name !== null ? a.name.toString() : ''
        bValue = b && b.name !== undefined && b.name !== null ? b.name.toString() : ''
        break
    }

    // Compare values based on the specified order
    if (sortOrder === 'asc') {
      return aValue.localeCompare(bValue)
    } else {
      return bValue.localeCompare(aValue)
    }
  })

  // console.log('Sorting by: email', sortOrder.value === 'asc' ? 'Ascending' : 'Descending')
  // filteredActivities.value.forEach(currentUser => {
  //   if (currentUser.email) {
  //     console.log(currentUser.email)
  //   } else {
  //     console.warn('Missing email for currentUser:', currentUser)
  //   }
  // })
}

const removePunctuationAndAccents = (text) => {
  const normalizedText = text.normalize('NFD')

  const withoutAccents = normalizedText.replace(/[\u0300-\u036f]/g, '')

  const withoutPunctuation = withoutAccents.replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, '')

  const withoutSpecialChars = withoutPunctuation.replace(/["'<>[\]]/g, '')

  const cleanedText = withoutSpecialChars.trim()

  return cleanedText
}

const filteredActivities = computed(() => {
  // Filter activities by selected category
  let activities = activitiesList.value.filter(a => a.category === selectedCategory.value._id)

  // Further filter activities based on the selected year
  activities = activities.filter(activity =>
    activity.recommended.some(rec => rec.year === selectedYear.value)
  )

  // If there's a search term, filter activities by name
  if (search.value !== '') {
    const searchTerm = removePunctuationAndAccents(search.value.toLowerCase())
    activities = activities.filter(activity =>
      removePunctuationAndAccents(activity.name.toLowerCase()).includes(searchTerm)
    )
  }

  return activities
})

const openRemoveActivityModel = (activityId) => {
  removeActivityId.value = activityId
  removeActivityModel.value = true
}

const openUpdateActivityModel = (activityId) => {
  updateActivityId.value = activityId
  updateActivityModel.value = true
}

const openRemoveCategoryModel = () => {
  removeCategoryModel.value = true
}

const openUpdateCategoryModel = () => {
  updateCategoryModel.value = true
}

const refresh = async (updatedCategoryName) => {
  selectedCategory.value = updatedCategoryName
  getRecommendedMonths()
}

</script>

<style scoped>
.currentUser {
  border-left: 4px solid lightgreen;
}

.bg-white {
  background-color: #ffffff;
  position: relative;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
}

.table-container {
  width: 100%;
}

.table-header {
  font-size: 0.9rem;
  color: #4b5563;
  text-transform: uppercase;
  background-color: #f3f4f6;
}

.action-link {
  color: #4f46e5;
  text-decoration: none;
}

.action-link:hover {
  text-decoration: underline;
}
</style>
