<template>
  <v-app>
    <!-- Navigation bar -->
    <nav>
      <v-app-bar color="teal" theme="dark" position="fixed">
        <v-icon color="white" class="ml-5">
          mdi-leaf
        </v-icon>

        <!-- Title -->
        <v-toolbar-title v-text="$route.name" />
      </v-app-bar>
    </nav>

    <main class="mt-12 pt-12">
      <v-container fluid>
        <v-row>
          <v-col xs="12" sm="12" md="10" offset-md="1" lg="8" offset-lg="2" xl="6" offset-xl="3" cols="12">
            <v-card>
              <v-card-text>
                <p>
                  Você pode efetuar <strong>login</strong> para ter mais benefícios. Usuários autenticados podem sincronizar seus dados com a
                  <a href="https://embrapa.br" target="_blank" rel="noopener">Embrapa</a>, de forma a <strong>recuperá-los em caso de perda ou troca do dispositivo</strong>
                  (computador, celular, tablet, etc).
                </p>
                <p>
                  <strong>Dispositivos autenticados que utilizem o mesmo e-mail compartilham dados entre si.</strong> Desta forma, as fazendas e
                  simulações criadas ou editadas neste dispositivo podem ser acessadas, p.e., em um computador ou vice-versa.
                </p>
                <p>
                  Além disso, seus dados poderão ser utilizados, <u>sempre de forma anônima</u>, em futuras pesquisas científicas para criação de tecnologias inovadoras para a agropecuária.
                </p>
              </v-card-text>
              <v-alert type="error" icon="mdi-alert" :model-value="error" transition="scale-transition" class="mx-2 my-0">
                {{ message }}
              </v-alert>
              <v-window v-model="step">
                <v-window-item class="px-3">
                  <v-btn variant="text" size="large" color="purple" class="white--text" block @click="showPrivacy" prepend-icon="mdi-gavel">
                    Política de Privacidade
                  </v-btn>
                  <v-row no-gutters class="py-6">
                    <v-col xs="12" sm="6" md="6" lg="6" xl="6" class="px-3 py-0" style="text-align: left;">
                      <v-switch label="Li e aceito os termos." v-model="agree" class="mt-3 ml-3" color="success"/>
                    </v-col>
                    <v-col xs="12" sm="6" md="6" lg="6" xl="6" class="px-3 py-0" style="text-align: right;">
                      <v-btn variant="text" color="success" size="large" :disabled="!agree" @click="step++" :block="$vuetify.display.xsOnly" append-icon="mdi-arrow-right">
                        Prosseguir
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-window-item>

                <v-divider v-if="step > 0 && !error" />

                <v-window-item>
                  <v-card-title>
                    Informe seu e-Mail
                  </v-card-title>
                  <v-form ref="form" lazy-validation>
                    <v-row no-gutters class="px-5">
                      <v-col
                        xs="12"
                        sm="12"
                        md="8"
                        offset-md="2"
                        lg="6"
                        offset-lg="3"
                        xl="6"
                        offset-xl="3"
                        cols="12"
                        class="px-1 pb-0"
                        style="text-align: center;"
                      >
                        <v-text-field
                          v-model="email"
                          append-icon="mdi-email"
                          outlined
                          clearable
                          label="e-Mail"
                          type="text"
                          :rules="rules"
                        />
                      </v-col>
                      <v-col xs="12" sm="12" md="8" offset-md="2" lg="6" offset-lg="3" xl="6" offset-xl="3" cols="12" class="px-1 pt-0">
                        <v-radio-group v-model="reliable" :row="!$vuetify.display.xsOnly" class="py-0 my-0">
                          <template v-slot:label>
                            <div>Este dispositivo é <strong>confiável</strong>?</div>
                          </template>
                          <v-radio
                            label="Sim"
                            :value="true"
                          />
                          <v-radio
                            label="Não"
                            :value="false"
                          />
                          <template v-slot:append>
                            <v-btn variant="text" icon="mdi-help-circle" @click="reliableInfo()" size="small">
                            </v-btn>
                          </template>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-form>
                  <v-card-actions>
                    <v-btn
                      color="error"
                      text
                      @click="cancel()"
                    >
                      Cancelar
                    </v-btn>

                    <v-spacer />

                    <v-btn
                      color="success"
                      depressed
                      :disabled="!validate()"
                      size="large"
                      @click="sendPin()"
                      :loading="loading"
                    >
                      Prosseguir
                      <v-icon class="ml-1">
                        mdi-arrow-right
                      </v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-window-item>
                <v-window-item>
                  <v-card-title>
                    Insira o PIN
                  </v-card-title>
                  <v-card-text>
                    Um número de 6 dígitos foi enviado para o e-mail <strong>{{ email }}</strong>. Por favor, insira-o abaixo para continuar:
                  </v-card-text>
                  <div class="input-wrapper my-5" style="width: 280px; margin: 0 auto;">
                    <v-otp-input v-model="pin"></v-otp-input>
                  </div>
                  <v-card-actions>
                    <v-btn
                      color="error"
                      text
                      @click="cancel()"
                    >
                      Cancelar
                    </v-btn>

                    <v-spacer />

                    <v-btn
                      color="success"
                      depressed
                      :disabled="pin.length !== 6"
                      size="large"
                      @click="authenticate()"
                      :loading="loading"
                      append-icon="mdi-check"
                    >
                      Autenticar
                    </v-btn>
                  </v-card-actions>
                </v-window-item>
              </v-window>
            </v-card>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-spacer></v-spacer>
          <v-col class="ma-15" align="center">
            <v-img src="@/assets/embrapa.png" style="width: 150px;"></v-img>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-container>
    </main>
    <!-- Privacy dialog -->
    <DialogWrapper v-model="privacyModel" @consent="agree = true" @dissent="agree = false" ref="dPrivacy" :newTitle="privacyTitle" :newMessage="privacyMessage" :newOptions="privacyOptions"/>
    <!-- Reliable dialog -->
    <DialogWrapper v-model="reliableModel" ref="dReliable" :newTitle="reliableTitle" :newMessage="reliableMessage" :newOptions="reliableOptions"/>
    <!-- Unauthorized dialog -->
    <DialogWrapper v-model="unauthorizedModel" ref="dunauthorized" :newTitle="unauthorizedTitle" :newMessage="unauthorizedMessage" :newOptions="unauthorizedOptions"/>
    <!-- Progress dialog -->
    <ProgressWrapper message="Autenticando... por favor, aguarde!" :size="70" color="purple lighten-2" ref="progress" />
  </v-app>
</template>

<script setup>
import { ref } from 'vue'
import CryptoJS from 'crypto-js'
import { useRouter } from 'vue-router'
import ProgressWrapper from '@/components/ProgressBar.vue'
import DialogWrapper from '@/components/ConfirmDialog.vue'
import axios from 'axios'
import { useUserStore } from '@/stores/userStore'
import { useSnackbarStore } from '@/stores/snackbarStore'

const snackbarStore = useSnackbarStore()
const userStore = useUserStore()

const router = useRouter()

// Reactive variables
const privacyModel = ref(false)
const privacyTitle = ref('Política de Privacidade')
const privacyMessage = ref('')
const privacyOptions = ref({ confirmText: 'Aceito', declineText: 'Não Aceito', width: 500 })

const reliableModel = ref(false)
const reliableTitle = ref('Dispositivo Confiável')
const reliableMessage = ref('<p>Um <strong>dispositivo confiável</strong> é um equipamento de <u>uso não compartilhado</u> (p.e., <strong>seu celular ou computador pessoal</strong>). Neste caso você permanecerá autenticado neste dispositivo por <u>tempo indeternimado</u>.</p>' +
    '<p>Caso esteja em um equipamento de uso compartilhado, tal como o computador de um saguão de hotel, marque <strong>NÃO</strong> nesta opção! Com isso, ao fechar o navegador, seu usuário será automaticamente deslogado.</p>')
const reliableOptions = ref({ confirmText: 'Ok', declineText: '' })

const unauthorizedModel = ref(false)
const unauthorizedTitle = ref('Usuário não encontrado')
const unauthorizedMessage = ref('Acesso negado!')
const unauthorizedOptions = ref({ confirmText: 'Entendi', declineText: '', width: 500 })

const step = ref(0) // Step of the process
const privacy = ref(null) // Privacy policy content
const agree = ref(false) // Agreement flag
const email = ref(null) // Email input
const pin = ref('') // PIN input
const reliable = ref(null) // Reliable device flag
const loading = ref(false) // Loading state
const error = ref(false) // Error state
const message = ref('') // Error message
const rules = ref([ // Validation rules for email input
  v => !v || /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(v) || 'O e-mail precisa ser válido!'
])

// Method to show privacy dialog
const showPrivacy = () => {
  axios.get('/privacy-policy.html').then(pol => {
    privacy.value = pol.data
    privacyMessage.value = privacy.value
    privacyModel.value = true
  })
}

// Method to show reliable dialog
const reliableInfo = () => {
  reliableModel.value = true
}

// Method to validate form
const validate = () => {
  return email.value !== null && reliable.value !== null
}

// Method to handle error messages
const errorMessage = error => {
  // Define how to handle different types of errors here
  // For example:
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    return `Erro ${error.response.status}: ${error.response.data.message}`
  } else if (error.request) {
    // The request was made but no response was received
    return 'Não foi possível receber uma resposta do servidor.'
  } else {
    // Something happened in setting up the request that triggered an Error
    return 'Erro ao enviar a requisição.'
  }
}

// Method to send PIN
const sendPin = () => {
  error.value = false

  if (!navigator.onLine) {
    message.value = 'É necessário uma conexão com a internet para prosseguir! Por favor, verifique suas configurações de rede ou tente novamente mais tarde.'

    error.value = true

    return
  }

  const err = error => {
    loading.value = false

    message.value = errorMessage(error)

    error.value = true
  }

  loading.value = true

  const api = import.meta.env.VITE_API

  axios.get(api + '/status', { timeout: 2000 }).then(() => {
    axios.post(api + '/manager/pin', { email: email.value }).then(() => {
      if (reliable.value) localStorage.setItem('email', email.value)

      loading.value = false

      step.value++
    }).catch(error => {
      console.log(error);
      snackbarStore.openSnackbar('Não foi possível enviar o PIN')
      unauthorizedModel.value = true
      cancel()
    })
  }).catch(error => {
    console.log(error);
    snackbarStore.openSnackbar()
  })
}

const authenticate = async () => {
  const error = ref(false)
  const loading = ref(false)
  const message = ref('')

  // Reset the error state
  error.value = false

  // Check network connectivity
  if (!navigator.onLine) {
    message.value = 'É necessário uma conexão com a internet para prosseguir! Por favor, verifique suas configurações de rede ou tente novamente mais tarde.'
    error.value = true
    return
  }

  loading.value = true

  try {
    const api = import.meta.env.VITE_API

    // Check API status
    await axios.get(`${api}/status`, { timeout: 2000 })

    // Authenticate the user
    const authResponse = await axios.post(`${api}/manager/authenticate`, {
      email: email.value,
      pin: pin.value
    })

    const token = authResponse.data.token

    // Get user details
    const userResponse = await axios.get(`${api}/user`, {
      headers: { Authorization: `Bearer ${token}` }
    })

    const user = {
      authenticated: true,
      name: userResponse.data.name,
      email: userResponse.data.email,
      admin: userResponse.data.admin,
      vegetal: userResponse.data.vegetal,
      animal: userResponse.data.animal,
      picture: '',
      token: token
    }

    const userEmailHash = CryptoJS.MD5(user.email).toString();

    // Fetch the user picture
    const pictureUrl = `https://www.gravatar.com/avatar/${userEmailHash}?s=200&d=identicon`

    try {
      await axios.get(pictureUrl)
      user.picture = pictureUrl
    } catch (error) {
      console.log('Error setting user picture:', error)
    }

    // Store user data in localStorage
    localStorage.setItem('user', JSON.stringify(user))
    localStorage.setItem('reliable', reliable.value)
    localStorage.setItem('email', '')

    // Update userStore and navigate to home page
    userStore.getUser()
    await router.push({ path: '/' })
  } catch (error) {
    console.error('Authentication error:', error)
    message.value = 'Ocorreu um erro ao autenticar. Por favor, tente novamente mais tarde.'
    error.value = true
  } finally {
    loading.value = false
  }
}

// Method to cancel process
const cancel = () => {
  localStorage.setItem('email', '')

  error.value = false
  agree.value = false

  email.value = ''
  reliable.value = null

  pin.value = ''

  loading.value = false

  step.value = 0
}
</script>
