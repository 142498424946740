import { defineStore, storeToRefs } from 'pinia'
import axios from 'axios'
import { useUserStore } from './userStore'
import { useSnackbarStore } from './snackbarStore'

const api = import.meta.env.VITE_API

const userStore = useUserStore()
const { currentUser } = storeToRefs(userStore)

export const useCategoriesAndActivitiesStore = defineStore('categoriesAndActivities', {
  state: () => ({
    categoriesList: [],
    activitiesList: [],
    activitiesByCategory: [],
    loading: false
  }),
  actions: {
    async fetchData() {
      const snackbarStore = useSnackbarStore()

      try {
        this.loading = true
        await axios.get(api + '/status', { timeout: 2000 });

        const categoryResponse = await axios.get(api + '/category/list', { headers: { Authorization: 'Bearer ' + currentUser.value.token } });
        this.categoriesList = categoryResponse.data;

        const activityResponse = await axios.get(api + '/activity/list', { headers: { Authorization: 'Bearer ' + currentUser.value.token } });
        this.activitiesList = activityResponse.data;

        this.loading = false
      } catch (error) {
        console.log('Error fetching categories or activities:', error);
        snackbarStore.openSnackbar('Não foi possível listar categorias ou atividades')
      }
    },
    getActivitiesByCategory(category_ID) {
      const snackbarStore = useSnackbarStore()

      try {
        this.loading = true
        this.activitiesByCategory = this.activitiesList.filter(a => a.category === category_ID)
        this.loading = false
      } catch (error) {
        console.log('Error getting activities for category ' + category_ID + ':', error);
        snackbarStore.openSnackbar('Não foi possível listar atividades')
      }
    }
  },
  getters: {
    getVegetalCategories: (state) => {
      const snackbarStore = useSnackbarStore()

      try {
        return state.categoriesList
          .filter(category => category.branch === 'vegetal')
          .map(category => ({
            _id: category._id,
            name: category.name,
            icon: category.icon,
            branch: category.branch,
            subbranch: category.subbranch
          }));
      }
      catch(error) {
        console.error(error);
        snackbarStore.openSnackbar('Não foi possível listar categorias vegetais')
      }
    },
    getAnimalCategories: (state) => {
      const snackbarStore = useSnackbarStore()

      try {
        return state.categoriesList
          .filter(category => category.branch === 'animal')
          .map(category => ({
            name: category.name,
            icon: category.icon,
            branch: category.branch,
            subbranch: category.subbranch
          }));
      }
      catch(error) {
        console.error(error);
        snackbarStore.openSnackbar('Não foi possível listar categorias animais')
      }
    }
  }
});
