<template>
  <v-app>
    <nav>
      <v-app-bar color="#009688" theme="dark" position="fixed">
        <v-icon color="white" class="ml-5" v-if="mdAndUp" @click.stop="home">
          mdi-leaf
        </v-icon>
        <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer" v-if="!mdAndUp" ></v-app-bar-nav-icon>

        <v-toolbar-title v-text="manifestData.short_name" v-if="mdAndUp" />

        <v-spacer></v-spacer>

        <v-btn
          v-if="stage === 'development'"
          color="red"
          variant="flat"
          prepend-icon="mdi-trash-can-outline"
          text="Apagar todas atividades"
          class="mr-3"
          @click="deleteActivities()"
        ></v-btn>

        <v-tooltip
          location="bottom"
        >
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props" icon="mdi-list-box-outline" size="large" class="ma-2" @click="navigateToChangesLog()"></v-btn>
          </template>
          <span>Histórico de Mudanças</span>
        </v-tooltip>
        
        <!-- Large Display -->
        <div class="d-flex align-center hidden-xs-and-down" style="margin-left: auto">
          <user-wrapper />
        </div>
      </v-app-bar>
      <!-- Progress Bar -->
      <v-progress-linear color="purple" indeterminate></v-progress-linear>
    </nav>


    <!-- MAIN NAV DRAWER -->
    <NavigationDrawer
      v-model="drawer"
      :home="goHome"
      :openUsersList="openUsersList"
      :openVegetalList="openVegetalList"
      :openAnimalList="openAnimalList"
      :lastClicked="lastClicked"
      @refresh="refresh"
    />

    <v-main class="ma-3">
      <div>
        <!-- <v-card variant="outlined" v-if="currentUser && currentUser.authenticated" class="mt-4 hidden-sm-and-up">
          <user-wrapper />
        </v-card> -->

        <!-- USERS LIST -->
        <UsersList v-model="openUsersList" />

        <!-- VEGETAL CATEGORIES LIST -->
        <VegetalCategoriesList
          v-model="openVegetalList"
          :years="availableYears"
          @refresh="openActivitiesList"
          @listYears="listAvailableYears"
        />

        <!-- ANIMAL CATEGORIES LIST -->
        <AnimalCategoriesList
          v-model="openAnimalList"
          :years="availableYears"
          @refresh="openActivitiesList"
        />

        <!-- SELECTED CATEGORY'S ACTIVITIES LIST -->
        <ActivitiesList
          v-model="activitiesListModel"
          :category="selectedCategory"
          :year="selectedYear"
          :openEmptyAlert="openEmptyAlert"
        />

        <!-- EMPTY ALERT -->
        <EmptyAlert v-if="emptyAlert" />

        <welcome-wrapper v-if="openWellcomeWrapper"/>
      </div>
    </v-main>

    <!-- SNACKBAR -->
    <MessageSnack />
  </v-app>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue'
import axios from 'axios'
import { useRouter } from 'vue-router'
import manifest from '../../public/manifest.json'
import UserWrapper from '@/components/UserProfile.vue'
import WelcomeWrapper from '@/components/WelcomeMessage.vue'
import NavigationDrawer from '@/components/NavigationDrawer.vue'
import EmptyAlert from '@/components/EmptyAlert.vue'
import UsersList from '@/components/UsersList.vue'
import VegetalCategoriesList from '@/components/VegetalCategoriesList.vue'
import AnimalCategoriesList from '@/components/AnimalCategoriesList.vue'
import ActivitiesList from '@/components/ActivitiesList.vue'
import { useDisplay } from 'vuetify'
import MessageSnack from '@/components/MessageSnack.vue'
import { useCategoriesAndActivitiesStore } from '@/stores/categoriesAndActivitiesStore'
import { useUserStore } from '@/stores/userStore'
import { useSnackbarStore } from '@/stores/snackbarStore'
import { storeToRefs } from 'pinia'

const categoriesAndActivitiesStore = useCategoriesAndActivitiesStore()
const snackbarStore = useSnackbarStore()
const userStore = useUserStore()
const { currentUser } = storeToRefs(userStore)

const api = import.meta.env.VITE_API
const stage = import.meta.env.VITE_STAGE

const { mdAndUp } = useDisplay()
const router = useRouter()

const goHome = ref(false)
const drawer = ref(false)
const emptyAlert = ref(false)

const openUsersList = ref(false)
const openYearsMenu = ref(false)
const openVegetalList = ref(false)
const openAnimalList = ref(false)

const activitiesListModel = ref(false)
const selectedCategory = ref(null)
const selectedYear = ref('')
const availableYears = ref([])

const lastClicked = ref('')

const manifestData = ref(manifest)
const openWellcomeWrapper = ref(true)

onMounted(async () => {
  categoriesAndActivitiesStore.fetchData()
})

watch(lastClicked, async () => {
  await listAvailableYears()
})

watch(openUsersList, () => {
  if (openUsersList.value) {
    openYearsMenu.value = false
    openVegetalList.value = false
    openAnimalList.value = false
    activitiesListModel.value = false
    openWellcomeWrapper.value = false
    lastClicked.value = 'users'
  }
})

watch(openVegetalList, () => {
  if (openVegetalList.value) {
    openUsersList.value = false
    openAnimalList.value = false
    openWellcomeWrapper.value = false
  }
})

watch(openAnimalList, () => {
  if (openAnimalList.value) {
    openUsersList.value = false
    openVegetalList.value = false
    openWellcomeWrapper.value = false
  }
})

const listAvailableYears = async () => {
  let activitiesList
  const yearsSet = new Set()
  await axios.get(api + '/status', { timeout: 2000 }).then(async () => {
    await axios.get(api + '/activity/list', { headers: { Authorization: 'Bearer ' + currentUser.value.token } })
      .then(response => {
        activitiesList = response.data

        activitiesList.forEach(activity => {
          if (activity.category?.branch === lastClicked.value) {
            activity.recommended.forEach(year => {
              yearsSet.add(year.year)
            })
          }
        })
        availableYears.value = Array.from(yearsSet)
        availableYears.value.push('Criar Novo')
      })
      .catch(error => {
        snackbarStore.openSnackbar('Não foi possível carregar os anos disponíveis')
        console.log(error)
      })
  }).catch(error => {
    snackbarStore.openSnackbar('Não foi possível carregar os anos disponíveis')
    console.log('Error fetching status:', error)
  })
}

const openActivitiesList = (category, year) => {
  selectedCategory.value = category
  selectedYear.value = year
  activitiesListModel.value = true
}

const navigateToChangesLog = () => {
  router.push({ name: 'Histórico de Mudanças' })
}

const home = () => {
  openUsersList.value = false
  openVegetalList.value = false
  openAnimalList.value = false
  activitiesListModel.value = false
  goHome.value = !goHome.value
  openWellcomeWrapper.value = true
}

const refresh = (usersList, vegetalList, animalList) => {
  emptyAlert.value = false
  openUsersList.value = usersList
  openVegetalList.value = vegetalList
  openAnimalList.value = animalList
  openYearsMenu.value = vegetalList || animalList
}

const openEmptyAlert = () => {
  console.log('openEmptyAlert')
  activitiesListModel.value = false
  emptyAlert.value = true
  if (!mdAndUp) drawer.value = false
}

const deleteActivities = async () => {
  await axios.get(api + '/status', { timeout: 2000 }).then(async () => {
    await axios.delete(api + '/manager/activity/all', { headers: { Authorization: 'Bearer ' + currentUser.value.token } })
      .then(response => {
        console.log(response.data)
        home()
        snackbarStore.openSnackbar('Todas as atividades foram deletadas com sucesso', 'success')
      })
      .catch(error => {
        console.log(error)
        snackbarStore.openSnackbar('Não foi possível deletar todas as atividades')
      })
  }).catch(error => {
    console.log('Error fetching status:', error)
    snackbarStore.openSnackbar('Não foi possível deletar todas as atividades')
  })
}

</script>
