<template>
  <div>
    <v-dialog
        v-model="newUserModel"
        persistent
        max-width="600px"
    >
        <v-card>
        <v-container>
            <v-row no-gutters>
            <v-text-field
                required
                v-model="newUserEmail"
                variant="outlined"
                hide-details
                label="Email"
            ></v-text-field>
            </v-row>
            <v-row no-gutters>
            <v-col cols="auto">
                <v-switch
                v-model="isAdmin"
                color="teal"
                label="administrador"
                class="ma-4"
                ></v-switch>
            </v-col>
            <v-col cols="3">
                <v-switch
                v-model="isVegetal"
                color="teal"
                label="vegetal"
                class="ma-4"
                :disabled="disableVegetal"
                ></v-switch>
            </v-col>
            <v-col cols="3">
                <v-switch
                v-model="isAnimal"
                color="teal"
                label="animal"
                class="ma-4"
                :disabled="disableAnimal"
                ></v-switch>
            </v-col>
            </v-row>
        </v-container>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
            color="error"
            variant="text"
            @click="newUserModel = false"
            prepend-icon="mdi-cancel"
            >
            Cancelar
            </v-btn>
            <v-btn
            color="teal"
            variant="text"
            @click="createUser"
            prepend-icon="mdi-plus"
            :disabled="disableCreateUser"
            >
            Criar
            </v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import axios from 'axios'
import { useUserStore } from '@/stores/userStore'
import { useSnackbarStore } from '@/stores/snackbarStore'
import { storeToRefs } from 'pinia'

const snackbarStore = useSnackbarStore()

const userStore = useUserStore()
const { currentUser } = storeToRefs(userStore)

const api = import.meta.env.VITE_API

const newUserModel = defineModel()

const disableCreateUser = ref(true)
const disableVegetal = ref(false)
const disableAnimal = ref(false)

const newUserEmail = ref('')
const isAdmin = ref(false)
const isVegetal = ref(false)
const isAnimal = ref(false)

const createUser = async () => {
  const newUser = {
    name: '',
    email: newUserEmail.value,
    admin: isAdmin.value,
    vegetal: isVegetal.value,
    animal: isAnimal.value
  }

  await axios.get(api + '/status', { timeout: 2000 }).then(async () => {
    await axios.post(api + '/user', { adminEmail: currentUser.value.email, user: newUser }, { headers: { Authorization: 'Bearer ' + currentUser.value.token } })
      .then(async () => {
        refresh()
        await axios.post(api + '/manager/revision', { userEmail: currentUser.value.email }, { headers: { Authorization: 'Bearer ' + currentUser.value.token } })
          .catch(error => {
            snackbarStore.openSnackbar('Não foi possível salvar as alterações no histórico')
            console.log(error)
          })
      })
      .catch(error => {
        snackbarStore.openSnackbar('Não foi possível criar o usuário')
        console.log(error)
      })
  }).catch(error => {
    snackbarStore.openSnackbar('Não foi possível criar o usuário')
    console.log('Error fetching status:', error)
  })
}

const refresh = () => {
  newUserModel.value = false
  snackbarStore.openSnackbar('Usuário criado com sucesso!', 'success')
  newUserEmail.value = ''
  isAdmin.value = false
  isVegetal.value = false
  isAnimal.value = false
  userStore.fetchUsers()
}

watch(isAdmin, () => {
  if (isAdmin.value) {
    isVegetal.value = true
    isAnimal.value = true
    disableVegetal.value = true
    disableAnimal.value = true
    disableCreateUser.value = false
  } else {
    isVegetal.value = false
    isAnimal.value = false
    disableVegetal.value = false
    disableAnimal.value = false
    disableCreateUser.value = true
  }
})

watch(isVegetal, () => {
  if (isVegetal.value) {
    disableCreateUser.value = false
  } else {
    if (isAnimal.value) {
      disableCreateUser.value = false
    } else {
      disableCreateUser.value = true
    }
  }
})

watch(isAnimal, () => {
  if (isAnimal.value) {
    disableCreateUser.value = false
  } else {
    if (isVegetal.value) {
      disableCreateUser.value = false
    } else {
      disableCreateUser.value = true
    }
  }
})

</script>
