<template>
  <v-snackbar
    v-model="showSnack"
    :color="snackColor"
    elevation="24"
    location="bottom"
  >
    {{ snackText }}
    <template v-slot:actions>
      <v-btn
        @click="showSnack = false"
        icon="mdi-close"
      ></v-btn>
    </template>
  </v-snackbar>
</template>

<script setup>
import { useSnackbarStore } from '@/stores/snackbarStore'
import { storeToRefs } from 'pinia'

const snackbarStore = useSnackbarStore()
const { snackText, snackColor, showSnack } = storeToRefs(snackbarStore)

</script>
