import { defineStore } from 'pinia';

export const useSnackbarStore = defineStore('snackbar', {
  state: () => ({
    snackText: 'Oops... Algo deu errado!',
    snackColor: 'red',
    showSnack: false,
  }),
  actions: {
    openSnackbar(message='Oops... Algo deu errado!', color='red') {
      this.snackText = message
      this.snackColor = color
      this.showSnack = true
    },
  },
});
