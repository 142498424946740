<template>
  <div>
    <v-dialog
      v-model="newCategoryModel"
      persistent
      max-width="600px"
  >
      <v-card>
      <v-container>
          <v-row no-gutters class="mb-3">
            <v-text-field
              required
              v-model="newCategoryName"
              variant="outlined"
              hide-details
              label="Nome da categoria"
            ></v-text-field>
          </v-row>
          <v-row no-gutters>
            <v-autocomplete
              v-model="selectedIcon"
              :items="icons"
              variant="outlined"
              hide-details
              label="Escolha o icone da categoria"
            >
              <template v-slot:item="{ props }">
                <v-list-item v-bind="props">
                  <template v-slot:prepend>
                    <v-icon> {{ 'mdi-' + props.title }} </v-icon>
                  </template>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-row>
      </v-container>
      <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
          color="error"
          variant="text"
          @click="newCategoryModel = false"
          prepend-icon="mdi-cancel"
          >
          Cancelar
          </v-btn>
          <v-btn
          color="teal"
          variant="text"
          @click="createCategory"
          prepend-icon="mdi-plus"
          >
          Criar
          </v-btn>
      </v-card-actions>
      </v-card>
  </v-dialog>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import axios from 'axios'
import { useUserStore } from '@/stores/userStore'
import { useSnackbarStore } from '@/stores/snackbarStore'
import { useCategoriesAndActivitiesStore } from '@/stores/categoriesAndActivitiesStore'
import { storeToRefs } from 'pinia'

const snackbarStore = useSnackbarStore()

const categoriesAndActivitiesStore = useCategoriesAndActivitiesStore()
const userStore = useUserStore()
const { currentUser } = storeToRefs(userStore)

const newCategoryModel = defineModel()
const emit = defineEmits(['refresh'])
const props = defineProps(['currentUser', 'branch'])

const selectedBranch = ref(props.branch)

const newCategoryName = ref('')
const icons = ref([])
const selectedIcon = ref(null)

onMounted(() => {
  fetchIcons()
})

const fetchIcons = async () => {
  try {
    await axios.get('https://cdn.jsdelivr.net/npm/@mdi/svg@7.4.47/meta.json').then(response => {
      response.data.forEach(icon => {
        icons.value.push(icon.name)
      })
    }).catch(error => {
      console.log('Error fetching icons:', error)
      snackbarStore.openSnackbar('Não foi possível achar ícones')
    })
  } catch (error) {
    console.error(error)
    snackbarStore.openSnackbar()
  }
}

const createCategory = async () => {
  const api = import.meta.env.VITE_API
  const newCategory = {
    name: newCategoryName.value,
    icon: 'mdi-' + selectedIcon.value,
    branch: selectedBranch.value,
  }

  await axios.get(api + '/status', { timeout: 2000 }).then(async () => {
    await axios.post(api + '/manager/category', { author: currentUser.value.email, category: newCategory }, { headers: { Authorization: 'Bearer ' + currentUser.value.token } })
      .then(async (res) => {
        console.log(res)
        snackbarStore.openSnackbar('Categoria criada com sucesso', 'success')
        refresh()
        await axios.post(api + '/manager/revision', { userEmail: currentUser.value.email }, { headers: { Authorization: 'Bearer ' + currentUser.value.token } })
          .catch(error => {
            snackbarStore.openSnackbar('Não foi possível salvar as alterações no histórico')
            console.log(error)
          })
      })
      .catch(error => {
        snackbarStore.openSnackbar('Não foi possível criar a categoria')
        console.log(error)
      })
  }).catch(error => {
    console.log('Error fetching status:', error)
    snackbarStore.openSnackbar()
  })
}

const refresh = () => {
  categoriesAndActivitiesStore.fetchData()
  newCategoryModel.value = false
  newCategoryName.value = ''
  selectedIcon.value = null
}

</script>
